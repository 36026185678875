import { useState, useEffect } from "react";

import svgX from "./assets/x.svg";

import SearchPage from "./components/SearchPage";
import ProceessingPage from "./components/ProceessingPage";
import SuccessPage from "./components/SuccessPage";
import FailPage from "./components/FailPage";
import { Route, useLocation } from "react-router-dom";


import * as axiosFns from "./axios";
import { waitFor } from "@testing-library/react";



const App = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [populateValue, setPopulateValue] = useState("");
    const [db, setDb] = useState([]);
    const [loading, setLoading] = useState(false);
    const [GivexOperationName, setGivexOperationName] = useState("");

    const [errorMsg, setErrorMsg] = useState(
        "Unknown error. Please contact your PMS provider."
    );

    const [xItem, setXitem] = useState("");
    const [xName, setXname] = useState("");
    const [xA3, setxA3] = useState("");
    
    const [buttons, setButtons] = useState([]);

    window.xName = xName;
    window.xItem = xItem;    

    const { search } = useLocation();
    let accountId = "";
    let shopId = "";
    let saleId = "";
    let workorderID = "";
    let extra1 = "";
    let extra2 = "";
    let extra3 = "";
    let extra4 = "";
    let extra5 = "";
    let extra6 = "";
    let extra7 = "";
    let registerID;
    let roomId = "";
    let refundId = "0";
    let operationName = "";
    let accountDataId = "";
    let guestName = "";
    let reservationId = "";
    let isPopulate = "";
    let customerId = "";
    let returnURL = "";


    let MySalqeData = "xx";

    let CurrentURL = window.location.href
    console.log('URL -> ',CurrentURL)
    let IsGivex = CurrentURL.includes("operation=givex");
    const [IsGivex905, setIsGivex905] = useState(false)
    const [IsGivex906, setIsGivex906] = useState(false)
    let IsCloudbeds = CurrentURL.includes("operation=CloudbedsK");
    let IsMaestro = CurrentURL.includes("operation=MaestroKMultiIntegra");
    let IsOpera = CurrentURL.includes("operation=OperaKMultiIntegra");
    let IsWRP = CurrentURL.includes("operation=WRPKMultiIntegraK");
    

    const parent = window.parent

    try {
        returnURL = search
            .split("&")
            .filter((item) => item.indexOf("returnURL") > -1)[0]
            .split("=")[1];
    } catch (err) {}
    returnURL = decodeURIComponent(returnURL);

    if (!!search) {
        try {
            customerId = search
                .split("&")
                .filter((item) => item.indexOf("customerID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            accountId = search
                .split("&")
                .filter((item) => item.indexOf("businessId") > -1)[0]
                .split("=")[1];
        } catch (err) {}
            
        try {
            saleId = search
                .split("&")
                .filter((item) => item.indexOf("saleID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            workorderID = search
                .split("&")
                .filter((item) => item.indexOf("workorderID") > -1)[0]
                .split("=")[1];
        } catch (err) {}
        //console.log(workorderID);



        try {
            extra1 = search
                .split("&")
                .filter((item) => item.indexOf("amount") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra2 = search
                .split("&")
                .filter((item) => item.indexOf("register_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra3 = search
                .split("&")
                .filter((item) => item.indexOf("currency") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra4 = search
                .split("&")
                .filter((item) => item.indexOf("retailer_payment_type_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            extra5 = search
                .split("&")
                .filter((item) => item.indexOf("origin") > -1)[0]
                .split("=")[1];
        } catch (err) {}


        try {
            extra6 = search
                .split("&")
                .filter((item) => item.indexOf("reference_id") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            shopId = search
                .split("&")
                .filter((item) => item.indexOf("locationId") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            registerID = search
                .split("&")
                .filter((item) => item.indexOf("registerID") > -1)[0]
                .split("=")[1];
        } catch (err) {}

        try {
            refundId = search
                .split("&")
                .filter((item) => item.indexOf("refund") > -1)[0]
                .split("=")[1];
        } catch (err) {}


        try {
            operationName = search
                .split("&")
                .filter((item) => item.indexOf("operation") > -1)[0]
                .split("=")[1];
        } catch (err) {}
    }


    useEffect(() => {
        if (operationName.indexOf("populate") > -1)
            axiosFns.getPopulate(
                setPopulateValue,
                accountId,
                customerId,
                operationName
            );
    }, []);


    useEffect(() => {
        if (!populateValue) return;
        axiosFns.getSearchData(
            accountId,
            shopId,
            registerID,
            operationName,
            workorderID,
            populateValue,
            setDb,
            setLoading
        );
        setSearchValue(populateValue);
    }, [populateValue]);

    //console.log(accountId);
    // 

    if (IsGivex) {
        operationName = GivexOperationName;
        operationName ? (
            console.log("")
        ) : (
            //operationName = "givex-integra-searchonly"
            operationName = "givex-integra-902"
        )
    };

    //console.log(operationName);

    const stringWithoutSpaces = searchValue.replace(/\s/g, '');
    const handleGetSearchData = () =>
        axiosFns.getSearchData(
            accountId,
            shopId,
            registerID,
            operationName,
            workorderID,
            stringWithoutSpaces,
            setDb,
            setLoading
        );

    try {
        //extra7 = document.getElementById('json-data').innerText;
        extra7 = document.getElementById('json-data').innerText;
    } catch (err) {}

    //console.log(extra7);

    const handleGetRoomCharges = (
        roomIdInput,
        accountDataIdX,
        guestNameX,
        reservationIdX
    ) => {
        roomId = roomIdInput;
        accountDataId = accountDataIdX;
        guestName = guestNameX;
        reservationId = reservationIdX;

        axiosFns.getRoomCharges(
            accountId, -9999999, //saleId,
            roomId,
            accountDataIdX,
            guestNameX,
            reservationIdX,
            refundId,
            operationName,
            extra1,
            extra2,
            shopId,
            extra4,
            extra5,
            extra6,
            extra7,
            setCurrentPage,
            setErrorMsg,
            setXitem,
            setXname
        );
    };

    //console.log("XXXXXXX", xItem)
    //console.log("XXXXXXX", xName)

    

    const handleGetRoomChargesRetry = () => {
        axiosFns.getRoomCharges(
            accountId, -9999999, //saleId,
            roomId,
            accountDataId,
            guestName,
            reservationId,
            refundId,
            operationName,
            setCurrentPage,
            setErrorMsg
        );
    };


    const handleClick = (e) => {
        //console.log("XXX")
        e.preventDefault();
        //console.log('The link was clicked.');
        //console.log(e.target.id);
        //console.log(CurrentURL);

        let OperationValue = "";
        try {
            OperationValue = search
                .split("&")
                .filter((item) => item.indexOf("operation") > -1)[0]
                .split("=")[1];
        } catch (err) {}


        console.log("-->>>>>",e.target.id);  //--- Operation name
        
        setGivexOperationName(e.target.id);
        
        //setxA3(e.target.id);
        //console.log("--**", GivexOperationName)

        setIsGivex905(e.target.id.includes("givex-integra-905"));
        setIsGivex906(e.target.id.includes("givex-integra-906"));
        //console.log("IsGivex905", IsGivex905)
        //console.log("IsGivex906", IsGivex906)

        console.log("--------------------")
        document.getElementById("operation-data").textContent = e.target.id;
        console.log(document.getElementById("operation-data").innerHTML)
        console.log("--------------------")

        document.getElementById("UrlPath").textContent = CurrentURL.replace(OperationValue, e.target.id);       
        document.querySelector("#root .XXXXXX").focus();

        let AppTitle = "";

        /*
        {
            IsGivex ? (
                AppTitle = "K-Series Givex (v1.6)"
            ) : (
                AppTitle = "K-Series Room Charges (v1.6)"
            )
        }
        */
        if (IsGivex) {
            AppTitle = "K-Series Givex (v3.0)";
        } else if (IsMaestro) {
            AppTitle = "K-Series Room Charges (Maestro)";
        } else if (IsOpera) {
            AppTitle = "K-Series Room Charges (Opera)";
        } else if (IsCloudbeds) {
            AppTitle = "K-Series Room Charges (Cloudbeds)";
        } else if (IsWRP) {
            AppTitle = "K-Series Room Charges (WRP)";
        } else {
            AppTitle = "K-Series Room Charges (v1.6)";
        }

        document.getElementsByClassName("TitleX")[0].innerHTML = AppTitle + "  ****   " + e.target.innerText;

    }


    function handleChange() {
        console.log("changed.....");
        console.log(document.getElementById("AmountPostPMS").value)
        //console.log(document.getElementById("AmountTipsPMS").value)
    }

    /*
    useEffect(() => {
        const fetchButtonData = async () => {
          try {
            var data = [];
              
            IsCloudbeds || IsMaestro || IsOpera ? (data=[{ name: "TIP 10%", value: "10" },
                { name: "TIP 15%", value: "15" },
                { name: "TIP 20%", value: "20" }]) : (data=[]);

            setButtons(data);
            console.log("TIPS -->",data)
          } catch (error) {
            console.error("Error fetching button data:", error);
          }
        };
    
        fetchButtonData();
      }, []);
    */
      useEffect(() => {
        const fetchButtonData = async () => {
          try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("X-Comapny-id", accountId);
            myHeaders.append("X-Customer-id", accountId);
            myHeaders.append("X-Operation", "GetTipListByCompany"); 
            let requestOptions = {
              method: "POST",
              headers: myHeaders,
            };
    
            const response = await fetch("https://retailrc.datastems.com/api/custom", requestOptions);
            const data = await response.json();
            setButtons(data);
            console.log("TIPS -->",data)
          } catch (error) {
            console.error("Error fetching button data:", error);
          }
        };
    
        fetchButtonData();
      }, []);

      const handleButtonClick = (buttonName, buttonValue) => {
        console.log("Button clicked:", buttonName, "with value:", buttonValue);
        // You can perform any additional actions here based on the button click
    
        const dnstuffValue = parseFloat(document.getElementById("AmountPostPMS").value);
        const calculatedValue = (dnstuffValue * buttonValue / 100.00).toFixed(2);
        document.getElementById("AmountTipsPMS").value = calculatedValue;
    
      };

    return ( 
        <Route path = "/">
        <div className = "app">
        <div className = "main-app">
            <div className = "header">
                <p class="TitleX">
                    (K) {IsGivex ? "K-Series Givex (v3.0) **** Redeem"  :
                    IsMaestro ? "K-Series Room Charges (Maestro)":
                    IsOpera ? "K-Series Room Charges (Opera)": 
                    IsCloudbeds ? "K-Series Room Charges (Cloudbeds)":
                    IsWRP ? "K-Series Room Charges (WRP)":
                    "Room Charges v2.2 DS"}
                </p>
            
                <label>
                    Amount:{' '}
                    <input 
                    onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }}
                    type="number" onChange={handleChange} onClick={(event) => event.target.select()} id="AmountPostPMS"
                    step="0.01" 
                    min="0" 
                    max="999999999999.99" 
                    text-align="right"
                    onBlur={(event) => {
                        event.target.value = parseFloat(event.target.value).toFixed(2);
                    }}
                    onC
                     />
                </label>

                {
                    IsCloudbeds || IsMaestro || IsOpera || IsWRP|| (IsGivex && !IsGivex905 && !IsGivex906) ? ( <label>
                        Tips:{' '}
                        <input 
                        onKeyPress={(event) => {
                                if (!/[0-9.]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }}
                        type="number" 
                        onChange={handleChange}
                        onClick={(event) => event.target.select()}
                        onBlur={(event) => {
                            event.target.value = parseFloat(event.target.value).toFixed(2);
                        }}
                        onC
                        id="AmountTipsPMS"
                        text-align="right" 
                        step="0.01" 
                        min="0" 
                        max="999999999999.99"
                        />
                    </label>
                    ) : ( <> </> 
                    )
                }
            </div>

        <div class = "container center" >
        <div id = "statusMessage" > 
        </div> 
        <div id = "outcomes" >
        <div class = "center-text" >
        <div class = "GivexClass" >

        {
            IsGivex ? ( 
                <button id = "givex-integra-902" onClick = { handleClick } className = "GivexButton1" >Redeem </button>
            ) : ( <> </>
            )
        }

        {
            IsGivex ? ( <button id = "givex-integra-905"onClick = { handleClick }className = "GivexButton3" >Add Balance </button>
            ) : ( <> </>)
        }
        
        {
            IsGivex ? ( <button id = "givex-integra-906"onClick = { handleClick }className = "GivexButton4" >Card Activation </button>
            ) : ( <> </>)
        
        } 

        </div> 
        </div> 
        </div> 
        </div>



        <div id = "payment-window">
        <div id = "payment-process">
        </div>



        </div> 
        <div className = "main-content">

        {
            IsGivex ? ( <> </>
            ) : ( <> </>
            )
        }

        
        {
            currentPage === 0 && ( <
                SearchPage db = { db }
                loading = { loading }
                handleGetSearchData = { handleGetSearchData }
                handleGetRoomCharges = { handleGetRoomCharges }
                setCurrentPage = { setCurrentPage }
                searchValue = { searchValue }
                setSearchValue = { setSearchValue }
                />
            )
        }

        

        { currentPage === 1 && < ProceessingPage / > } { currentPage === 2 && < SuccessPage / > } {
            currentPage === 3 && ( <
                FailPage handleGetRoomChargesRetry = { handleGetRoomChargesRetry }
                setCurrentPage = { setCurrentPage }
                xItem = { xItem }
                xName = { xName }
                xA3 = { xA3 }
                errorMsg = { errorMsg }
                />
            )
        }

     

        <div className="button-row">
            {buttons.map((button, index) => (
              <button className="green-button" key={index} onClick={() => handleButtonClick(button.name, button.value)}>
                {button.name}
              </button>
            ))}
        </div>

        </div> 
        </div> 
        </div> 
        </Route>
        

    );


};

export default App;